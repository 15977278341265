import React from 'react';
import { Link } from 'gatsby';

import * as styles from './header.module.css';
import Logo from '../logo';

const Header = () => {
	return (
		<nav className={styles.nav}>
			<div className={styles.container}>
				<Link to='/'>
					<Logo fill='white' size='45' />
				</Link>

				<ul className='links'>
					{/* TODO: Uncomment these once pages are created */}
					<li>
						<Link to='/'>Home</Link>
					</li>
					<li>
						<Link to='/research'>Research</Link>
					</li>
					{/* <li>
						<Link to='/'>Communication</Link>
					</li> */}
					<li>
						<Link to='/contact'>Contact</Link>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default Header;
