import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTwitterSquare,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import * as styles from './footer.module.css';

const Footer = () => {
	const {
		linkedInProfile,
		twitterProfile,
		headshotCredit,
	} = useStaticQuery(graphql`
		{
			contentfulHomePage {
				linkedInProfile
				twitterProfile
				headshotCredit
			}
		}
	`).contentfulHomePage;

	return (
		<footer className={styles.footer}>
			<p>&copy; {new Date().getFullYear()} Andrew Nine</p>

			<div className='icons'>
				<a href={linkedInProfile} target='blank'>
					<FontAwesomeIcon
						icon={faLinkedin}
						className={styles.icon}
						size='lg'
					/>
				</a>
				<a href={twitterProfile} target='blank'>
					<FontAwesomeIcon
						icon={faTwitterSquare}
						className={styles.icon}
						size='lg'
					/>
				</a>
			</div>

			<div>
				<p>
					Headshot &copy; 2015{' '}
					<a href={headshotCredit} target='blank'>
						Ian Martin
					</a>{' '}
				</p>
				<p>
					Website built by{' '}
					<a href='https://cbroaders12.github.io/' target='blank'>
						Conor Broaders
					</a>
				</p>
			</div>
		</footer>
	);
};

export default Footer;
