import React from 'react';

const Logo = (props) => {
	return (
		<svg
			width={props.size}
			height={props.size}
			viewBox='0 0 60 60'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g id='andrewLogo'>
				<path
					id='outerRing'
					d='M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM2.65182 30C2.65182 45.104 14.896 57.3482 30 57.3482C45.104 57.3482 57.3482 45.104 57.3482 30C57.3482 14.896 45.104 2.65182 30 2.65182C14.896 2.65182 2.65182 14.896 2.65182 30Z'
					fill={props.fill}
				/>
				<g id='initials'>
					<path
						id='A'
						d='M10.7389 43H7.49118C7.07017 43 6.91981 42.7444 7.0401 42.2333L21.9846 14.7667C22.135 14.2556 22.3488 14 22.9201 14C22.9201 14 28.316 14 28.9174 14C29.5189 14 29.2933 14.2556 29.4136 14.7667C29.5339 15.2779 29.4136 42.2333 29.4136 42.2333C29.5339 42.7444 29.3685 43 28.9174 43H25.7148C25.4441 43 25.2487 42.9549 25.1284 42.8647C25.0382 42.7444 24.963 42.534 24.9028 42.2333V36.3701H14.9685L11.5509 42.2333C11.4306 42.7444 11.16 43 10.7389 43ZM25.1284 18.2097L16.8395 32.7621H24.9028L25.1284 18.2097Z'
						fill={props.fill}
					/>
					<path
						id='9'
						d='M37.8367 28.297H43.9357V21.1711C43.9357 18.9461 42.8756 17.8336 40.7555 17.8336H37.8367C35.7456 17.8336 34.7001 18.9461 34.7001 21.1711V25.0047C34.7001 27.1996 35.7456 28.297 37.8367 28.297ZM41.3654 43H32.5219C31.97 43 31.6941 42.7444 31.6941 42.2333V39.888C31.6941 39.4069 31.97 39.1664 32.5219 39.1664H40.7555C42.8466 39.1664 43.8921 38.0539 43.8921 35.8289V32.1757H37.1832C35.0341 32.1757 33.3641 31.5744 32.1733 30.3717C30.9826 29.1389 30.3872 27.4251 30.3872 25.2302V20.9907C30.3872 18.7657 30.9826 17.0518 32.1733 15.8491C33.3641 14.6164 35.0341 14 37.1832 14H41.3654C43.5146 14 45.1845 14.6164 46.3753 15.8491C47.566 17.0518 48.1614 18.7657 48.1614 20.9907V36.0093C48.1614 38.2043 47.566 39.9181 46.3753 41.1509C45.1845 42.3836 43.5146 43 41.3654 43Z'
						fill={props.fill}
					/>
				</g>
			</g>
		</svg>
	);
};

export default Logo;
